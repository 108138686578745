$( document ).ready( function() {

	/* - - - - - - -        init inputmask       - - - - - - */
	$( '[type="tel"]' ).inputmask( '+38 (999) 999 99 99' );

	/* - - - - - - -        faq toggle       - - - - - - */
	$( document ).on( 'click', '.faq-box__title', function() {
		const $parent = $( this ).closest( '.faq-box' );
		if ( !$parent.hasClass( 'show' ) ) {
			$( '.faq-box' ).removeClass( 'show' );
			$( '.faq-box__text' ).slideUp( 300 );
			$parent.addClass( 'show' );
			$parent.find( '.faq-box__text' ).slideDown( 300 );
		} else {
			$parent.removeClass( 'show' );
			$parent.find( '.faq-box__text' ).slideUp( 300 );
		}
	});

	/* - - - - - - -        scrollto       - - - - - - - - */
	$( document ).on( 'click', '.js-scroll-to', function( e ) {
		e.preventDefault();
		const link = $( this ).attr( 'href' );
		$( 'html, body' ).animate({ 'scrollTop': $( link ).offset().top }, 300 );
	});

	/* - - - - - - -        stage slider       - - - - - - - - */
	const stageSlider = $( '.stage-slider__content' ).slick({
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		swipeToSlide: false,
		speed: 300,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					adaptiveHeight: true,
				}
			}
		]
	}).on( 'init reInit afterChange', function( event, slick, currentSlide ) {

		//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
		if ( slick.slideCount ) {
			var i = ( currentSlide ? currentSlide : 0 ) + 1;
			var current = ( i < 10 ) ? '0' + i : i;
			var count = ( slick.slideCount < 10 ) ? '0' + slick.slideCount : slick.slideCount;
			$( '.stage-slider__content .slide-current' ).text( current );
			$( '.stage-slider__content .slide-count' ).text( '/ ' + count );
		}
	});
	const stageSliderNav = $( '.stage-slider__tabs' ).on( 'init', function( event, slick ) {
		$( '.stage-slider__tabs .slick-slide.slick-current' ).addClass( 'is-active' );
	}).slick({
		infinite: false,
		slidesToShow: 5,
		slidesToScroll: 5,
		arrows: false,
		dots: false,
		swipeToSlide: false,
		speed: 300,
		focusOnSelect: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					variableWidth: true
				}
			}
		]
	});
	stageSlider.on( 'afterChange', function( event, slick, currentSlide ) {
		$( '.stage-slider__tabs' ).slick( 'slickGoTo', currentSlide );
		$( '.stage-slider__tabs .slick-slide.is-active' ).removeClass( 'is-active' );
		$( '.stage-slider__tabs .slick-slide[data-slick-index="' + currentSlide + '"]' ).addClass( 'is-active' );
	});
	stageSliderNav.on( 'click', '.slick-slide', function( event ) {
		event.preventDefault();
		const goToSingleSlide = $( this ).data( 'slick-index' );
		$( '.stage-slider__content' ).slick( 'slickGoTo', goToSingleSlide );
	});

	/* - - - - - - -        init ionRangeSlider       - - - - - - - - */
	$( '.js-range' ).each( function() {
		const $range = $( this ),
			$parent = $range.closest( '.range-box' ),
			$inputFrom = $parent.find( '.range-box__value-from' ),
			min = parseInt( $range.attr( 'data-min' ) ),
			max = parseInt( $range.attr( 'data-max' ) ),
			valueFrom = parseInt( $range.attr( 'data-from' ) );
		let instance;
		$range.ionRangeSlider({
			type: 'single',
			grid: false,
			hide_from_to: true,
			hide_min_max: true,
			// min: min,
			// max: max,
			// from: valueFrom,
			onStart: updateInputs,
			onChange: updateInputs
		});
		instance = $range.data( 'ionRangeSlider' );
		function updateInputs( data ) {
			const from = data.from;
			$inputFrom.val( prettify( from ) );
		}
		$inputFrom.on( 'input', function() {
			let val = parseInt( $( this ).prop( 'value' ) );
			// validate
			if ( val <= min ) {
				val = min;
			} else if ( val >= max ) {
				val = max;
			}
			instance.update({
				from: val
			});
		});
		$inputFrom.on( 'blur', function() {
			let val = parseInt( $( this ).prop( 'value' ) );
			if ( val <= min || !val ) {
				val = min;
			} else if ( val >= max ) {
				val = max;
			}
			$inputFrom.val( prettify( val ) );
		});
	});

	function prettify( num ) {
		let n = num.toString();
		return n.replace( /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ' );
	}

	/* - - - - - - -        calculator toggle       - - - - - - - - */
	$( document ).on( 'click', '.js-calculator-toggle', function() {
		const $parent = $( this ).closest( '.calculator-form__box' );
		if ( !$parent.hasClass( 'show' ) ) {
			$parent.addClass( 'show' );
			$parent.find( '.calculator-form__box-body' ).slideDown( 300 );
		} else {
			$parent.removeClass( 'show' );
			$parent.find( '.calculator-form__box-body' ).slideUp( 300 );
		}
	});

	/* - - - - - - -        calculator calculate       - - - - - - - - */
	function calculate() {
		const area = parseInt( $( '.range-box__value-from' ).val() );
		let priceBase = 0,
			price = 0,
			total = 0,
			multiplier = 1;

		priceBase = ( typeof $( '.range-box__value-from' ).attr( 'data-price' ) === 'string' ) ? eval( $( '.range-box__value-from' ).attr( 'data-price' ) ) : $( '.range-box__value-from' ).attr( 'data-price' );
		if ( typeof priceBase === 'number' ) {
			total += priceBase;
		}
		if ( typeof priceBase === 'object' ) {
			total += priceBase.find( item => area <= item.max ).price;
		}

		$( '.check-switch' ).each( function() {
			const $checkField = $( this ).find( '[type="checkbox"]' );
			if ( $checkField.prop( 'checked' ) ) {
				price = ( typeof $checkField.attr( 'data-price' ) === 'string' ) ? eval( $checkField.attr( 'data-price' ) ) : $checkField.attr( 'data-price' );
				if ( $checkField.attr( 'data-multiplier' ) !== undefined && $checkField.attr( 'data-multiplier' ) !== '' ) {
					multiplier = parseInt( $checkField.attr( 'data-multiplier' ) );
				}
				if ( typeof price === 'number' ) {
					total += price * area * multiplier;
				}
				if ( typeof price === 'object' ) {
					total += price.find( item => area <= item.max ).price * multiplier;
				}
			}
		});

		if ( $( '#material' ).prop( 'checked' ) ) {
			priceBase = ( typeof $( '.range-box__value-from' ).attr( 'data-price-material' ) === 'string' ) ? eval( $( '.range-box__value-from' ).attr( 'data-price-material' ) ) : $( '.range-box__value-from' ).attr( 'data-price-material' );
			if ( typeof priceBase === 'number' ) {
				total += priceBase;
			}
			if ( typeof priceBase === 'object' ) {
				total += priceBase.find( item => area <= item.max ).price;
			}

			$( '.check-switch' ).each( function() {
				const $checkField = $( this ).find( '[type="checkbox"]' );
				if ( $checkField.prop( 'checked' ) ) {
					price = ( typeof $checkField.attr( 'data-price-material' ) === 'string' ) ? eval( $checkField.attr( 'data-price-material' ) ) : $checkField.attr( 'data-price-material' );
					if ( $checkField.attr( 'data-multiplier' ) !== undefined && $checkField.attr( 'data-multiplier' ) !== '' ) {
						multiplier = parseInt( $checkField.attr( 'data-multiplier' ) );
					}
					if ( typeof price === 'number' ) {
						total += price * area * multiplier;
					}
					if ( typeof price === 'object' ) {
						total += price.find( item => area <= item.max ).price * multiplier;
					}
				}
			});
		}

		$( '.calculator-form__total .price' ).text( prettify( total ) );
	}
	$( document ).on( 'click', '.js-calculate', function( e ) {
		e.preventDefault();
		calculate();
	});

	/* - - - - - - - - - - - - - -   Отправка формы  - - - - - - - - - - - - - - - - */
	$( '.send-button' ).click( function() {
		let validate = 1;
		const $form = $( this ).closest( 'form' );
		$form.find( '.validate' ).each( function( key, value ) {
			if( $( this ).val() === '' ){
				validate = 0;
				$( this ).focus();
				$( this ).closest( '.form-group' ).removeClass( 'has-success' ).addClass( 'has-error' );
			} else {
				$( this ).closest( '.form-group' ).removeClass( 'has-error' ).addClass( 'has-success' );
			}
		});
		if( validate === 1 ){
			$.ajax({
				url: 'ajax.php',
				data: 'action=send_form&' + $form.serialize(),
				success: function( data ){
					$( '.modal' ).modal( 'hide' );
					$( '#modal-success' ).modal( 'show' );
				}
			});
		} else {
			/*alert(validate_msg);*/
		}
	});
});
